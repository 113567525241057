:root {
  --new-blue: #1c212d;
  --white: #ffffff;
  --silver: #fbfbfb;
  --light: #c4ccca;
  --light-black: #616c7a;
  --dark-silver: #717982;
  --body-bg: #f8f8f9;
  --black: #000;
  --active-pagination: #f9fafb;
  --red: #c82717;
}
