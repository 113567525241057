@import "../../Common.scss";
@import "../../Function.scss";

.sidebar {
  width: 18vw;
  height: 100vh;
  background: var(--new-blue);
  padding: rem(30) rem(15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top {
    h3 {
      margin-bottom: rem(15);
      color: var(--silver);
      font-weight: 600;
      text-align: center;
      font-weight: 700;
      font-size: rem(25);
      line-height: rem(30);
      color: #f8f7f8;
    }
    .links {
      display: flex;
      flex-direction: column;
      a {
        display: flex;
        align-items: center;
        padding: rem(8) rem(24);
        border-radius: rem(5);
        font-weight: 600;
        transition: 0.3s ease-in-out;
        background: var(--dark-silver);
        &:not(:last-child) {
          margin-bottom: rem(8);
        }
        svg {
          margin-right: rem(8);
        }
        &:hover {
          background: var(--white);
        }
      }
      .active_link {
        background: var(--white) !important;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    .accaunt {
      display: flex;
      align-items: center;
      margin-bottom: rem(10);
      .user {
        width: rem(40);
        height: rem(40);
        margin-right: rem(10);
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        h5 {
          font-weight: 500;
          font-size: rem(14);
          margin: 0;
          color: var(--white);
        }
        p {
          color: var(--light-black);
          margin: 0;
        }
      }
    }
    .sign_out {
      border-radius: rem(7);
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(17);
    }
  }
}

@media only screen and (max-width: 900px) {
  .sidebar {
    width: 100% !important;
    height: auto !important;
    position: sticky;
    left: 0;
    z-index: 99;
    top: 0;
    .top {
      display: none;
    }
    .bottom {
      display: none;
    }
  }
}
