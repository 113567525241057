@import "../../Common.scss";
@import "../../Function.scss";

.services {
  background: var(--body-bg);
  width: 100% !important;
  height: 100vh !important;
  overflow-y: auto;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(20) rem(30);
    h3 {
      font-weight: 250;
      font-size: rem(32);
      line-height: rem(38);
      color: #000000;
      margin: 0;
    }
    button {
      border-radius: rem(7);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: rem(20);
        height: rem(20);
        margin-right: rem(7);
      }
    }
  }
  .bottom {
    :global {
      .pagination_new {
        padding: rem(20);
      }
      .ant-table-thead {
        .ant-table-cell {
          background: var(--body-bg);
          border-top: rem(1) solid #bebebe;
          border-bottom: 0;
          font-weight: 600;
          font-size: rem(12);
          line-height: rem(15);
          text-transform: uppercase;
          color: #616c7a;
          &::before {
            display: none;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 600;
          font-size: rem(14);
          line-height: rem(15);
          color: var(--new-blue);
          button {
            border: 0;
            background: transparent;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: rem(7);
            width: max-content;
            height: max-content;
            svg {
              width: rem(20);
              height: rem(20);
              path {
                stroke: var(--new-blue);
              }
            }
          }
        }
      }
    }
    .search {
      width: rem(405);
      height: rem(35);
      border: rem(1) solid #c6c6c6 !important;
      border-radius: rem(7);
      background: var(--white);
      margin: 0 0 rem(20) rem(30);
      input {
        background: var(--white) !important;
        &::placeholder {
          font-weight: 400;
          font-size: rem(13);
          line-height: rem(16);
          color: #c4ccca;
        }
      }
    }
    .table {
      .center {
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        &_elem {
          text-align: center !important;
        }
      }
    }
  }
}

.service_modal {
  :global {
    .ant-modal-content {
      border-radius: rem(7) !important;
      label {
        &::before {
          display: none !important;
        }
      }
    }
    .ant-modal-footer {
      display: none;
    }
  }
  .form {
    .price {
      width: 100%;
      height: rem(38);
      border-radius: rem(7) !important;
      background: var(--white);
      input {
        background: var(--white) !important;
        border-radius: rem(7);
        &::placeholder {
          font-weight: 400;
          font-size: rem(13);
          line-height: rem(16);
          color: #c4ccca;
        }
      }
    }
    .name {
      background: var(--white) !important;
      border-radius: rem(7);
      height: rem(38);
      &::placeholder {
        font-weight: 400;
        font-size: rem(13);
        line-height: rem(16);
        color: #c4ccca;
      }
    }
    .submit {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 0 !important;
      button {
        border-radius: rem(7);
      }
    }
  }
}
