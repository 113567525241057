@import "../../Common.scss";
@import "../../Function.scss";

.new_suggestion {
  width: 100% !important;
  height: 100vh;
  overflow-y: auto;
  padding: rem(20) 0;
  background: #f8f8f9;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: rem(30);
    padding-bottom: rem(10);

    .right {
      display: flex;
      align-items: center;
      column-gap: rem(10);
      flex-direction: row-reverse;

      button {
        border-radius: rem(7);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin: 0 rem(5) 0 0;
        }
      }
    }
  }

  h1 {
    font-weight: 250;
    font-size: rem(32);
    line-height: rem(38);
    color: #222222;
    margin: 0 0 0 rem(30);
    display: flex;
    align-items: center;

    button {
      width: max-content !important;
      height: max-content !important;
      border-radius: rem(7) !important;
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: center;
      margin-right: rem(8);

      svg {
        width: rem(24);
        height: rem(24);
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 rem(30);

    .form {
      height: rem(50);
      display: flex;
      align-items: center;

      :global {
        .ant-select {
          .ant-select-selector {
            width: max-content !important;
            border-radius: rem(7) !important;
            .ant-select-selection-search-input {
              width: 100% !important;
            }
          }
        }
      }

      input {
        border-radius: rem(7);
        width: rem(300);
      }

      button {
        border-radius: rem(7);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin: 0 rem(5) 0 0;
        }
      }
    }

    .price {
      h2 {
        display: flex;
        align-items: center;
        margin: 0 !important;

        span {
          margin: 0 rem(8);
          font-size: rem(26);
          color: var(--new-blue);
        }
      }
    }
  }

  .bottom {
    :global {
      .ant-table-thead {
        .ant-table-cell {
          background: var(--body-bg);
          border-top: rem(1) solid #bebebe;
          border-bottom: 0;
          font-weight: 600;
          font-size: rem(12);
          line-height: rem(15);
          text-transform: uppercase;
          color: #616c7a;

          &::before {
            display: none;
          }
        }
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 600;
          font-size: rem(14);
          line-height: rem(15);
          color: var(--new-blue);
        }
      }
    }

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(20) rem(30);

      h2 {
        font-weight: 400;
        font-size: rem(22);
        line-height: rem(38);
        color: #222222;
      }

      label {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(17);
        color: #868686;
      }

      input {
        width: rem(200);
        border-radius: rem(7) !important;
      }

      .left {
        display: flex;
        flex-direction: column;
        row-gap: rem(5);
        .input {
          width: rem(255);
          border-radius: rem(7);
          background: var(--white);

          input {
            background: var(--white) !important;

            &::placeholder {
              font-weight: 400;
              font-size: rem(13);
              line-height: rem(16);
              color: #c4ccca;
            }
          }
        }
      }

      .center {
        display: flex;
        flex-direction: column;
        row-gap: rem(5);
      }

      .date {
        display: flex;
        flex-direction: column;
        row-gap: rem(5);
        :global {
          .ant-input-affix-wrapper {
            border-radius: rem(7) !important;
          }
        }
        input {
          width: rem(200);
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        row-gap: rem(5);

        :global {
          .ant-input-affix-wrapper {
            border-radius: rem(7);
            width: rem(200);
          }
        }
      }
    }

    .table {
      .center {
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;

        &_elem {
          text-align: center !important;
        }
      }

      .btn {
        background: transparent;
        padding: 0;
        width: max-content !important;
        height: max-content !important;
        border: 0;
        border-radius: rem(7);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s linear;

        svg {
          width: rem(20);
          height: rem(20);

          path {
            stroke: var(--new-blue);
          }
        }

        &_delete {
          path {
            stroke: var(--red) !important;
          }
        }
      }
    }
  }
}
