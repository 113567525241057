// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import "./Function.scss";
@import "./Common.scss";

* {
  // font-family: "Inter", sans-serif !important;
  font-family: "Space Grotesk", sans-serif;
  &::-webkit-scrollbar {
    width: rem(4.5);
    height: rem(4.5);
    background: transparent;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-silver);
    border-radius: rem(30);
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  &::-webkit-scrollbar {
    width: rem(4.5);
    height: rem(4.5);
    background: transparent;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-silver);
    border-radius: rem(30);
  }
}

.main_page {
  padding: 0;
  margin: 0;
  &_elements {
    display: flex;
    width: 100%;
  }
}

.ant-table-body {
  &::-webkit-scrollbar {
    width: rem(3.2);
    height: rem(3.2);
    background: transparent;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: rem(30);
  }
  &.ant-scrolling-effect {
    width: calc(100% - rem(3.2)) !important;
    .header {
      width: calc(100% - rem(3.2)) !important;
    }
  }
}

.service_modal {
  .ant-modal-content {
    padding-top: rem(25);
    .ant-modal-footer {
      display: none !important;
    }
    .ant-modal-body {
      form {
        .ant-form-item {
          width: 100%;
          &:last-child {
            display: flex;
            width: 100%;
            justify-content: flex-end !important;
            margin-bottom: 0;
          }
          .ant-form-item-row {
            display: flex !important;
            justify-content: space-between !important;
          }
          .ant-form-item-label {
            width: 24%;
            display: flex;
            justify-content: start !important;
          }
        }
      }
    }
  }
}

.check_group {
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow-y: auto;
}

.pagination_new {
  .ant-pagination {
    display: flex;
    align-items: center;
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
      background: #ffffff;
      border: rem(1) solid #d0d5dd;
      border-radius: rem(8) 0 0 rem(8);
      display: flex;
      align-items: center;
      width: max-content;
      justify-content: center;
      padding: rem(16) rem(12);
      margin: 0;
      transition: 0.2s linear;
      &:hover {
        background: #e4ecf2;
      }
      span {
        font-weight: 500 !important;
        font-size: rem(14) !important;
        line-height: rem(20) !important;
        color: #1d2939 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter" !important;
        svg {
          margin-right: rem(8);
        }
      }
    }
    .ant-pagination-item {
      border-radius: 0 !important;
    }
    .ant-pagination-item-active {
      background: #e4ecf2;
    }
    .ant-pagination-next {
      border-radius: 0 rem(8) rem(8) 0 !important;
      svg {
        margin: 0 0 0 rem(8) !important;
      }
    }
    .ant-pagination-disabled {
      opacity: 0.55;
    }
  }
}

.d_f {
  display: flex;
}

.jc_c {
  justify-content: center;
}

.ai_c {
  align-items: center;
}

.jc_sb {
  justify-content: space-between;
}

.w_100 {
  width: 100% !important;
}

.fd_c {
  flex-direction: column;
}

.fd_r {
  flex-direction: row;
}

.notBtn {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent !important;
  box-shadow: none;
  width: max-content;
  height: max-content;
}

.ml_10 {
  margin-left: rem(10);
}

.mb_10 {
  margin-bottom: rem(10);
}

@media only screen and (max-width: 900px) {
  .main_page {
    &_elements {
      flex-direction: column;
    }
  }
}
