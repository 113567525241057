@import "../../Function.scss";
@import "../../Common.scss";

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #e4ecf2;
  position: relative;
  :global {
    .login_form {
      width: 100%;
      &_item {
        .ant-input-affix-wrapper {
          background: transparent !important;
          border-radius: rem(15);
          padding: 0 rem(11);
          z-index: 2;
          .ant-input-suffix {
            svg {
              fill: var(--new-blue);
            }
          }
        }
        input {
          background: transparent !important;
          height: rem(50);
          border-radius: rem(15);
          font-size: rem(16);
          width: 100%;
          color: var(--new-blue);
          margin: 0 !important;
          &::placeholder {
            color: var(--new-blue) !important;
          }
        }
        button {
          width: 100%;
          height: rem(50);
          font-size: rem(18);
          font-weight: 700;
          border-radius: rem(15);
          z-index: 2;
          background: var(--new-blue);
          color: var(--white);
        }
      }
    }
  }
  &_up {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    > h1 {
      font-weight: 700;
      font-size: rem(48);
      line-height: rem(62);
      color: var(--new-blue);
      margin: 0;
    }
    > h5 {
      font-weight: 700;
      font-size: rem(18);
      line-height: rem(23);
      color: var(--text-light);
      margin: 0 0 rem(40) 0;
    }
  }
  &_circle {
    &_topLeft {
      position: absolute;
      top: rem(-250);
      border-radius: 50%;
      left: rem(-200);
      width: rem(500);
      height: rem(500);
      background: var(--new-blue);
    }
    &_bottomLeft {
      position: absolute;
      bottom: rem(-370);
      border-radius: 50%;
      left: rem(-200);
      width: rem(500);
      height: rem(500);
      background: var(--new-blue);
    }
    &_bottomRight {
      position: absolute;
      bottom: rem(-20);
      right: rem(-20);
      width: rem(500);
      height: rem(500);
      svg {
        width: 100% !important;
        height: 100% !important;
        path {
          fill: var(--new-blue) !important;
        }
      }
    }
  }
}
