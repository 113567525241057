@import "../../Function";
@import "../../Common";

.definition {
  width: 100%;
  height: 100vh;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(20);
  }
  .bottom {
    display: flex;
    flex-direction: column;
    row-gap: rem(20);
  }
  :global {
    .edit_table {
      display: flex;
      align-items: center;
      column-gap: rem(20);
      button {
        &:first-child,
        &:nth-child(2) {
          svg {
            path {
              stroke: #1d2939;
              stroke-width: rem(0.5) !important;
            }
          }
        }
      }
    }
  }
}

.definition_modal {
  :global {
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      padding-bottom: 0;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: rem(20);
    padding-bottom: rem(20);
  }
}
