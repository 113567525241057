@import "../../Common.scss";
@import "../../Function.scss";

.suggestion {
  background: var(--body-bg);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 0;
  .info {
    height: 100%;
    width: 100%;
    border-radius: rem(5);
    padding: rem(20) 0;
    .top {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 rem(15) rem(30) rem(15);
      h3 {
        font-weight: 250;
        font-size: rem(32);
        line-height: rem(38);
        color: #000000;
        margin: 0;
      }
      a {
        background: #ffffff;
        border: rem(1) solid #d9d9d9;
        border-radius: rem(7);
        padding: rem(5) rem(15);
        background: var(--blue);
        color: var(--new-blue);
        border-radius: rem(5);
        display: flex;
        align-items: center;
        transition: 0.3s linear;
        &:hover {
          color: var(--white);
          background: var(--new-blue);
        }
        svg {
          margin-right: rem(5);
        }
      }
      .search {
        width: rem(405);
        height: rem(35);
        border: rem(1) solid #c6c6c6 !important;
        border-radius: rem(7);
        background: transparent;
        input {
          background: transparent !important;
          &::placeholder {
            font-weight: 400;
            font-size: rem(13);
            line-height: rem(16);
            color: #c4ccca;
          }
        }
      }
    }
    .bottom {
      :global {
        .pagination_new {
          padding: rem(20);
        }
        .ant-table-thead {
          .ant-table-cell {
            background: var(--body-bg);
            border-top: rem(1) solid #bebebe;
            border-bottom: 0;
            font-weight: 600;
            font-size: rem(12);
            line-height: rem(15);
            text-transform: uppercase;
            color: #616c7a;
            &::before {
              display: none;
            }
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(15);
            color: var(--new-blue);
          }
        }
      }
      .table {
        .center {
          text-align: center;
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
          align-items: center;
          &_elem {
            text-align: center !important;
          }
        }
        .btn {
          background: transparent;
          padding: 0;
          width: max-content !important;
          height: max-content !important;
          border: 0;
          border-radius: rem(7);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s linear;
          svg {
            width: rem(20);
            height: rem(20);
            path {
              stroke: var(--new-blue);
            }
          }
          &_delete {
            path {
              stroke: var(--red) !important;
            }
          }
        }
      }
    }
  }
  &_edit {
    width: 84vw;
    background: var(--linkBg);
    padding: rem(20);
    &_form {
      height: 9vh;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      &_price {
        padding-right: rem(40);
        h2 {
          display: flex;
          align-items: center;
          margin: 0;
          span {
            color: var(--blue);
            margin: 0 rem(6);
            font-size: rem(30);
            transition: 0.3s ease-in-out;
          }
        }
      }
    }
    &_table {
      h2 {
        font-size: rem(16);
      }
      &_tables {
        width: 100%;
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .suggestion {
    .info {
      .top {
        padding: rem(15);
        h3 {
          display: none;
        }
        a {
          width: max-content !important;
          display: flex;
        }
        .search {
          width: rem(200);
        }
      }
      .bottom {
        :global {
          .ant-table-thead {
            .ant-table-cell {
              padding: rem(8) rem(5);
              font-size: rem(10);
              line-height: 100%;
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              padding: rem(5);
              font-size: rem(12);
            }
          }
        }
      }
    }
  }
}
