@import "../../Function.scss";

.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50% !important;
    height: 100vh;
  }

  .left {
    background: #00a389;
    border-radius: 0 rem(16) rem(16) 0;
    position: relative;
    overflow: hidden;

    .logo {
      background: #008771;
      border-radius: 50%;
      width: 128px;
      height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rem(40);
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      /* identical to box height */

      color: #ffffff;
    }

    .circle1,
    .circle2,
    .circle3 {
      position: absolute;
      border-radius: 50%;
    }

    .circle1 {
      width: rem(57);
      height: rem(57);
      border: rem(15) solid #fff;
      top: rem(-30);
      left: rem(120);
    }

    .circle2 {
      width: rem(300);
      height: rem(300);
      border: rem(50) solid #fff;
      bottom: rem(0);
      left: rem(-200);
    }

    .circle3 {
      width: rem(100);
      height: rem(100);
      border: rem(22) solid #fff;
      bottom: rem(80);
      right: rem(-50);
    }
  }
}

.pdf {
  width: 100vw;
  padding: rem(40);
  height: 100vh;
  overflow: hidden;
  position: relative;

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
    margin: 0;
    margin-bottom: rem(19);
    padding: 0 17px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 17px;
    height: max-content;
    padding-bottom: rem(12);

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #353535;
    }

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #00a389;
    }
  }

  .bottom {
    display: flex;
    //align-items: center;
    //justify-content: space-between;
    flex-direction: column;
    row-gap: rem(20);
    width: 100%;
    padding-top: rem(24);

    //h4 {
    //  font-weight: 500;
    //  font-size: 16px;
    //  line-height: 19px;
    //  color: #353535;
    //}
    //
    //div {
    //  background: #00a389;
    //  padding: rem(9) rem(18);
    //  font-weight: 700;
    //  font-size: 16px;
    //  line-height: 19px;
    //  color: #ffffff;
    //}

    .price {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;

      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        margin: 0;
        color: #737373;
      }

      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: rem(10);
        color: #00a389;

        .not {
          color: #737373;
          text-decoration: line-through;
          text-decoration-thickness: 3px;
        }
      }
    }
  }

  .body {
    border-radius: 12px;
    border: rem(2) solid #00a389;
    //border-top: rem(30) solid #00a389;
    display: flex;
    flex-direction: column;
    gap: 0;

    .top {
      border-bottom: rem(2) solid #00a389;
      padding: 0;

      .left,
      .center,
      .right {
        width: 33% !important;
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #00a389;
          margin: 0;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: rem(8);

        h1 {
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #00a389;
          margin: 0;
        }

        .not {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-decoration-line: line-through;
          color: #787878;
        }
      }

      .center {
        display: flex;
        width: 33.4%;
        border-right: rem(2) solid #00a389;
        border-left: rem(2) solid #00a389;

        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          padding: 0;
          margin: 0;
          color: #353535;
          width: 50.6%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: rem(2) solid #00a389;
        }

        .definitions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          padding: 0;
          margin: 0;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
            color: #353535;
            padding: rem(10) 0 !important;
            width: 100% !important;
            text-align: center;

            &:not(&:first-child) {
              border-top: rem(2) solid #00a389;
            }
          }
        }
      }
    }

    .header {
      display: flex;
      text-align: center;
      background: #00a389;
      border-radius: 11px 11px 0 0;

      h2 {
        width: 33%;
        margin: 0;
        padding: 10px 0;
        color: #fff;
        font-size: 16px;
      }
    }

    .bottom {
      padding: 0;
      display: flex;
      flex-direction: column;
      row-gap: 0 !important;

      .add_title {
        color: #00a389;
        margin: 0 !important;
        padding: 10px 0;
        font-size: 16px;
        width: 100%;
        text-align: center;
        border-bottom: rem(2) solid #00a389;
      }

      .table {
        height: 100%;
        width: 100%;

        tr {
          td {
            width: 33.3%;
            border: 0;
            padding: rem(20) rem(10);
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            text-align: center;
            color: #353535;

            &:first-child,
            &:nth-child(2) {
              font-size: rem(18);
              border-right: rem(2) solid #00a389;
            }
          }

          &:not(&:first-child) {
            border-top: rem(2) solid #00a389;
          }
        }
      }
    }
  }

  .circle1,
  .circle2,
  .circle3 {
    position: absolute;
    z-index: -1;
    border-radius: 50%;
  }

  .circle1 {
    width: rem(57);
    height: rem(57);
    border: rem(15) solid #00a389;
    top: rem(-30);
    left: rem(120);
  }

  .circle2 {
    width: rem(300);
    height: rem(300);
    border: rem(50) solid #00a389;
    bottom: rem(-120);
    left: rem(-150);
  }

  .circle3 {
    width: rem(100);
    height: rem(100);
    border: rem(22) solid #00a389;
    bottom: rem(80);
    right: rem(-50);
  }
}

.page {
  width: 100vw;
  height: 100vh;

  .container {
    padding: rem(32) rem(48);
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #000000;
    }

    .contents {
      display: flex;
      align-items: center;
      min-width: rem(768);
      height: 100% !important;

      .left {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: rem(16);

        .item {
          width: 100%;
          display: flex;
          align-items: center;
          column-gap: rem(16);

          p {
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #353535;
            width: 100%;
          }

          .line {
            width: rem(8) !important;
            border-radius: 50% !important;
            height: rem(8) !important;
            background: #00a389;
          }
        }
      }

      .right {
        height: 100%;
        width: 50%;
        position: relative;

        img {
          position: absolute;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.pageContainer {
  width: 100vw;
  height: 100vh;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}
